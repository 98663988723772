<template>
  <div class="share">
    <!-- <span><img :src="baseUrl+'/uploads/images/0fb9ca8157fa752d8697d106f65b8561.png'"></span> -->
    <span></span>
    <span @click="copy_article">
      <img src="@/assets/weixin.png" alt="">
      <div class="wx-qr">
        <img :src="$img + props.qrCode" alt="">
      </div>
    </span>
    <span @click="handlerToWb"><img src="@/assets/weibo.png" alt=""></span>
  </div>
</template>

<script setup>
import { ElNotification } from 'element-plus'

let copy_article = () => {
  return
  const url = window.location.href
  copy_to_clipboard(url)
  ElNotification({
    title: '复制成功',
    message: '分享链接已复制，快去分享吧！',
    type: 'success',
    position: 'top-right',
    zIndex: '99999999999'
  })
}


let props = defineProps({
  title: {
    type: String,
    default: ''
  },
  qrCode: {
    type: String
  }
})

// 复制文本到剪切板函数
function copy_to_clipboard(txt_str) {
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.setAttribute('value', txt_str);
  input.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  document.body.removeChild(input);
}
// 分享到微博
// 跳转到微博
const handlerToWb = () => {
  let href = location.href
  window.open(
    `https://service.weibo.com/share/share.php?url=${href}&title=${props.title}`,
    '_blank',
  )
}
</script>

<style lang="less" scoped>
@maxw: 128rem;

.share {
  width: 50/@maxw;
  height: 115/@maxw;
  cursor: pointer;

  img {
    width: 35/@maxw;
    height: 29/@maxw;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 50/@maxw;
    height: 50/@maxw;
    background: #B8C9E3;
    transition: ease .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  >span:nth-child(1) {
    position: relative;
    z-index: 9;
    background-image: url("../assets/fx_one.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  span:nth-of-type(2) {
    .wx-qr {
      position: absolute;
      right: .4297rem;
      width: 1.1719rem;
      height: 1.1719rem;
      background-color: #f3f3f3;
      opacity: 1;
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      padding: .0781rem;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      .wx-qr {
        opacity: 1;
        visibility: visible;
      }
    }
  }

}

.share:hover {
  >span:nth-child(1) {
    background-image: url("../assets/fx.png");
  }

  >span:nth-child(2) {
    top: 52/@maxw;
  }

  >span:nth-child(3) {
    top: 104/@maxw;
  }
}
</style>