<template>
  <div class="article_box_xq" ref="navs">
    <h1 ref="h12">{{ wz.author ? wz.author + ':' + wz.title : wz.title }}</h1>
    <p>发布日期：{{ wz.created_at ? wz.created_at.slice(0, 10) : "加载中..." }}</p>
    <nav v-html="htmltext" ref="" class="nav___"></nav>
    <share class="share" :title="wz.author ? wz.author + ':' + wz.title : wz.title" :qrCode="shareImg" />
  </div>
</template>

<script setup>
import { get_Navaeticle_xq, get_aeticle_xq } from '@/api/API.js'
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute, } from 'vue-router'
import Replacebaseurl from '@/hooks/useReplacebaseurl.js'
import usedownloadAttachments from '@/hooks/usedownloadAttachments.js'
import base64_ from '@/hooks/useBase64text.js'
import useShare from '@/hooks/useShare'
import share from '@/components/share.vue'
let htmltext = ref('')
let wz = ref('')
const route = useRoute()
let navs = ref(null)
let routeData = JSON.parse(base64_.decode(route.params.obj))

// 根据路由传的id获取文章详情
let shareImg = ref('')
let getarticle_wz_id = async (ID) => {
  const { data: res } = await get_aeticle_xq({
    ...ID,
    share_url: window.location.href
  })
  if (res.code == 200 && navs.value) {
    navs.value.style.opacity = 1
    navs.value.style.margin = "0"
    navs.value.style['margin-left'] = 30 / 128 + 'rem'
    wz.value = res.data
    let share = {
      title_en: res.data.title,
      description: res.data.description,
      thumb: res.data.thumb
    }
    shareImg.value = res.data.wx_share_img
    useShare(share)
    // usedownloadAttachments函数是如果文章详情有附件，就下载附件拼接到最后。
    htmltext.value = Replacebaseurl(res.data.content, 'https://apisem.ucas.ac.cn/') + usedownloadAttachments(res.data.pic_url)
  }
}

onMounted(() => {
  document.documentElement.scrollTop = 0
  // 监听路由变化就根据传过来的导航id获取对应文章数据+动画效果
  navs.value.style.opacity = 0
  navs.value.style.margin = "500px 0 0 0"
  navs.value.style['margin-left'] = 30 / 128 + 'rem'
  getarticle_wz_id({ id: routeData.article_wz_id })
})

</script>

<style lang="less" scoped>
@maxw: 128rem;

.article_box_xq {
  position: relative;
  width: 100%;
  font-size: 15/@maxw;
  margin: 0 30/@maxw;
  transition: ease .8s;

  h1 {
    word-break: break-word;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 32/@maxw;
    font-family: fantBold;
    font-weight: 300 !important;
    color: #174994;
    margin: 0;
    padding: 0 0 20/@maxw 0;
  }

  p {
    width: 1073/@maxw;
    height: 46/@maxw;
    background: linear-gradient(to right, #F0F0F0 0%, #D9D9D9 100%);
    border-top: 2px solid #517DBE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 26/@maxw;
    font-size: 20/@maxw;
    font-family: Source Han Sans CN-Medium;
    font-weight: 500;
    color: #5A5A5A;
  }

  nav {
    width: 100%;
    transition: ease .8s;
    margin: 30/@maxw 0 0 0;

    /deep/ p,
    /deep/ div,
    /deep/ span {
      font-size: 18px;
      font-family: '微软雅黑';
      line-height: 1.5;
      color: #5A5A5A;
    }
  }

  .share {
    position: absolute;
    right: -161/@maxw;
    top: 180/@maxw;
  }
}

// 动画函数
@keyframes pageYs {
  from {
    transform: translateY(800/@maxw);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width:750px) {
  @maxw_: 750/100vw;

  .article_box_xq {
    margin-left: 0 !important;

    >h1 {
      font-size: 35/@maxw_;
    }

    >p {
      margin: 10/@maxw_ 0 0 0 !important;
      width: 100%;
      height: 46/@maxw_;
      font-size: 25/@maxw_;
    }

    >nav {
      font-size: 30/@maxw_ !important;
      overflow: hidden;
      font-family: Source Han Sans CN-Bold !important;
    }
  }
}</style>  