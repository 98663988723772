export default (content, base_url) => {
  //处理富文本框图片没有地址的
  var imgInfo = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
  var allImg = content.match(imgInfo); // 取到所有img标签 放到数组allImg里面
  if (allImg) { //可能没有匹配
      for (var i = 0; i < allImg.length; i++) {
          var srcImg = allImg[i].replace(imgInfo, '$1'); //取src面的内容
          const str = srcImg.split('uploads')
          if (srcImg.indexOf("uploads") != -1) {
              content = content.replace(str[0], base_url); //替换地址
          }
      }
  }
  return content
}