import axios from 'axios'
import wx from 'weixin-js-sdk'
// 微信分享功能
const jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData'] // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
export default function (par) {
  let text = window.localStorage.getItem("gw")
  if (text == 1999) {
    console.log("🚀 ~ file: useShare.js:6 ~ par:", par)
  }
  let source = window.location.href
  let urlImg = 'https://' + window.location.host
  axios
    .post('https://xtss.wechat.itxiangtian.cn/api/wechat/getJsSdk', {
      source
    })
    .then(({ data: res }) => {
      let params = {
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: jsApiList // 必填，需要使用的JS接口列表
      }

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        ...params
      })
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      wx.ready(function () {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        wx.updateAppMessageShareData({
          title: par.title_en || '中国科学院大学', // 分享标题
          desc: par.description || '中国科学院大学经济与管理学院', // 分享描述
          link: source, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: urlImg + par.thumb || urlImg + 'logo.png', // 分享图标
          success: function () {
            // 设置成功
          }
        })

        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
        wx.updateTimelineShareData({
          title: par.title_en || '中国科学院大学', // 分享标题
          link: source, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: urlImg + par.thumb || urlImg + 'logo.png', // 分享图标
          success: function () {
            // 设置成功
          }
        })
      })
    })
}
